body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(74, 25, 0, 0.582)),
    url("../public/tart.png");
  background-position: center center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 100px;
  font-weight: bold;
  color: #ffd700; /* A gold color for a 'coin' effect */
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  background: linear-gradient(40deg, #ffdc13, #ff4400);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  animation: pop 0.5s ease-in-out infinite alternate;
  margin: 0px;
}

p {
  font-family: "Indie Flower", cursive;
  font-size: 34px;
  color: #ffffff;
}

.links {
  display: flex;
  gap: 20px;
}

.ca {
  background-color: #8b3800;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Courier New", Courier, monospace;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  h1 {
    font-size: 100px;
  }

  p {
    font-size: 30px;
  }
}

@media (max-width: 992px) {
  h1 {
    font-size: 80px;
  }

  p {
    font-size: 28px;
  }

  .links {
    flex-direction: row;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 60px;
  }

  p {
    font-size: 24px;
  }
  .ca {
    background-color: #8b3800;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Courier New", Courier, monospace;
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  h1 {
    font-size: 50px;
  }

  p {
    font-size: 22px;
  }

  .links {
    gap: 5px;
  }
  .ca {
    background-color: #8b3800;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Courier New", Courier, monospace;
    font-size: 14px;
  }
}
