.App {
  text-align: center;
  
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-left: 80px;
  position: relative;
}

.App-link {
  color: #bd0000;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .App-header {
    align-items: center; /* Center align items for mobile */
    margin-left: 0; /* Remove left margin for mobile */
  }
}